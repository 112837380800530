import request from '@/utils/request'

// 商品列表
export function goods_index(data) {
  return request({
    url: '/merchant/goods/index',
    method: 'get',
    params: data
  })
}

// 创建商品
export function goods_add(data) {
  return request({
    url: '/merchant/goods/add',
    method: 'post',
    data: data
  })
}
// 编辑商品
export function goods_edit(data) {
  return request({
    url: '/merchant/goods/edit',
    method: 'post',
    data: data
  })
}
// 删除商品
export function goods_del(data) {
  return request({
    url: '/merchant/goods/del',
    method: 'post',
    data: data
  })
}
// 商品详情
export function goods_detail(data) {
  return request({
    url: '/merchant/goods/detail',
    method: 'get',
    params: data
  })
}

// 商品上下架
export function goods_changeShelves(data) {
  return request({
    url: '/merchant/goods/changeShelves',
    method: 'post',
    data: data
  })
}
// 修改商品排序
export function goods_changeSort(data) {
  return request({
    url: '/merchant/goods/changeSort',
    method: 'post',
    data: data
  })
}
// 缺货商品补库存
export function goods_changeStock(data) {
  return request({
    url: '/merchant/goods/changeStock',
    method: 'post',
    data: data
  })
}

// 添加品牌
export function brand_add(data) {
  return request({
    url: '/merchant/brand/add',
    method: 'post',
    data: data
  })
}
// 品牌列表
export function brand_index(data) {
  return request({
    url: '/merchant/brand/index',
    method: 'get',
    params: data
  })
}
// 编辑品牌
export function brand_edit(data) {
  return request({
    url: '/merchant/brand/edit',
    method: 'post',
    data: data
  })
}
// 删除品牌
export function brand_del(data) {
  return request({
    url: '/merchant/brand/del',
    method: 'post',
    data: data
  })
}
// 更新品牌状态
export function brand_status(data) {
  return request({
    url: '/merchant/brand/status',
    method: 'post',
    data: data
  })
}




