import request from '@/utils/request'

// 订单列表
export function orders_index(data) {
  return request({
    url: '/merchant/orders/index',
    method: 'get',
    params: data
  })
}

// 待发货订单列表
export function orders_unready_index(data) {
  return request({
    url: '/merchant/orders/unready',
    method: 'get',
    params: data
  })
}

// 订单详情
export function orders_read(data) {
  return request({
    url: '/merchant/orders/read',
    method: 'get',
    params: data
  })
}
// 修改发货地址
export function orders_consignee(data) {
  return request({
    url: '/merchant/orders/consignee',
    method: 'post',
    data: data
  })
}
// 修改订单备注
export function orders_remark(data) {
  return request({
    url: '/merchant/orders/remark',
    method: 'post',
    data: data
  })
}
// 发货
export function orders_delivery(data) {
  return request({
    url: '/merchant/orders/delivery',
    method: 'post',
    data: data
  })
}

// 开单
export function orders_doReady(data) {
  return request({
    url: '/merchant/orders/doReady',
    method: 'post',
    data: data
  })
}

// 售后列表
export function orders_after_index(data) {
  return request({
    url: '/merchant/afterSale/index',
    method: 'get',
    params: data
  })
}

// 售后审核
export function orders_after_sale(data) {
  return request({
    url: '/merchant/afterSale/review',
    method: 'post',
    data
  })
}


