<!-- 店铺设置 -->
<style scoped>
	.w100{
		width: 100px;
	}
	.pText{
		font-size: 14px;
		line-height: 19px;
	}
</style>
<template>
	<div class="pl60 pr60 pt40 pb40" >
		<a-card title="订单详情" :bordered="true" class="w-100">
			<div slot="extra" >
				<a-button size="small" class="ml10" type="primary" v-if="order_data.order_status_gather==2||order_data.order_status_gather==3"  @click="dialogClick('logi')">
				  发货
				</a-button>
				<a-button size="small" class="ml10" v-if="order_data.order_status_gather<3"  @click="dialogClick('consignee')">
				  修改收货地址
				</a-button>
				<!-- <a-button size="small" class="ml10" type="dashed"  @click="dialogClick('remark',row)">
				  修改订单备注
				</a-button> -->
			</div>
			<!-- <div slot="extra" v-if="order_data.delivery_status==0||order_data.delivery_status==1"><a-button @click="dialogClick" type="primary">发货</a-button></div> -->
			<a-tabs default-active-key="1" border @change="callback">
		      	<a-tab-pane key="1" tab="基本信息">
		      		<a-descriptions :bordered="true" title="订单信息" :column="{ xs: 5, sm: 4, md: 3}">
		      		    <a-descriptions-item label="订单号">{{order_data.order_sn}}</a-descriptions-item>
						<a-descriptions-item label="客户姓名">{{order_data.user.nickname}}</a-descriptions-item>
						<a-descriptions-item label="订单状态汇总">{{order_status_gathers[order_data.order_status_gather]}}</a-descriptions-item>
		      		    
						<a-descriptions-item label="支付状态">{{order_data.pay_status?'已支付':'未支付'}} </a-descriptions-item>
		      		    <a-descriptions-item label="发货状态">{{order_data.delivery_status==1?'部分发货':order_data.delivery_status==2?'已发货':'未发货'}}</a-descriptions-item>
		      		    <a-descriptions-item label="购买数量">{{order_data.goods_num}} </a-descriptions-item>

						<a-descriptions-item label="商品总价">¥{{$common.toDecimal2(order_data.goods_amount/100)}} </a-descriptions-item>
						<a-descriptions-item label="订单总额">¥{{$common.toDecimal2(order_data.order_amount/100)}}</a-descriptions-item>
						<a-descriptions-item label="支付总额">¥{{$common.toDecimal2(order_data.payed_amount/100)}}</a-descriptions-item>
						
						<a-descriptions-item label="优惠券抵扣">{{$common.toDecimal2(order_data.coupon_discount / 100)}}</a-descriptions-item>
						<a-descriptions-item label="积分抵扣">{{$common.toDecimal2(order_data.point_discount / 100)}}</a-descriptions-item>
						<a-descriptions-item label="下单时间">{{order_data.ctime}}</a-descriptions-item>

						<a-descriptions-item label="是否会员礼包">{{order_data.is_vip_package ? "是" : "否"}}</a-descriptions-item>
						<a-descriptions-item label="是否推荐礼包">{{order_data.is_recommend_package ? "是" : "否"}}</a-descriptions-item>
					</a-descriptions>
					<a-descriptions :bordered="true" title="收货人信息" class="mt20" :column="{ xs: 5, sm: 4, md: 3}">
						<a-descriptions-item label="收货人姓名">{{order_data.consignee_name}} </a-descriptions-item>
						<a-descriptions-item label="收货人电话">{{order_data.consignee_mobile}}</a-descriptions-item>
						<a-descriptions-item label="收货人地址">{{order_data.consignee_area+'-'+order_data.consignee_address}} </a-descriptions-item>
						
					</a-descriptions>
		      	</a-tab-pane>
		      	<a-tab-pane key="2" tab="商品信息" force-render>
		      		<a-table rowKey="id" :columns="goods_columns" :data-source="order_data.ordersItems?order_data.ordersItems:[]" bordered>
						<a-space slot='discount_price' slot-scope="item,row,index">
							<span>¥{{$common.toDecimal2(order_data.order_amount/order_data.goods_amount)}}</span>
						</a-space> 
						<a-space slot='brand_id' slot-scope="item,row,index">
							<span>{{findBrandName(item)}}</span>
						</a-space> 
						<a-space slot='discount_price' slot-scope="item,row,index">
							<span>¥{{$common.toDecimal2(order_data.order_amount/order_data.goods_amount)}}</span>
						</a-space> 
						<a-space slot='price' slot-scope="item,row,index">
							<span>¥{{$common.toDecimal2(row.price/100)}}</span>
						</a-space>
						<a-space slot='payed_amount' slot-scope="item,row,index">
							<span>¥{{$common.toDecimal2(row.payed_amount/100)}}</span>
						</a-space>
						<a-space slot='img' slot-scope="item,row,index">
							<a-avatar shape="square" :size="44" :src="IMGHOST+row.img" />
						</a-space>
						<a-space slot='is_delivery' slot-scope="item,row,index">
							<span>{{row.is_delivery==1?'已发货':'未发货'}}</span>
						</a-space>
						<a-space slot='after_sale_status' slot-scope="item,row,index">
							<span>{{row.after_sale_status==1?'售后中':'-'}}</span>
						</a-space>
						<!-- <a-space slot='discount_amount' slot-scope="item,row,index">
							<span>¥{{$common.toDecimal2(row.discount_amount/100)}}</span>
						</a-space> -->
					</a-table>
		      	</a-tab-pane>
		      	<a-tab-pane key="3" tab="支付单">
		      		<a-descriptions title="支付单" ></a-descriptions>
					<a-table rowKey="payment_id"  :columns="pay_columns" :data-source="order_data.payments?order_data.payments:[]" bordered>
						<a-space slot='trade_status' slot-scope="item,row,index">
							<span>{{row.trade_status==1?'成功':row.trade_status==0?'等待支付':'失败'}}</span>
						</a-space>
						<a-space slot='payment' slot-scope="item,row,index">
							<span>{{row.payment=='wechat'?'微信支付':row.payment=='alipay'?'支付宝支付':row.payment=='balance'?'余额支付':'-'}}</span>
						</a-space>
						<a-space slot='fee' slot-scope="item,row,index">
							<span>¥{{$common.toDecimal2(row.fee/100)}}</span>
						</a-space>
					</a-table>
				</a-tab-pane>
				<a-tab-pane key="4" tab="发货单">
					<a-descriptions title="发货单" ></a-descriptions>
					<a-table rowKey="sn" :columns="ship_columns" :data-source="order_data.delivery?order_data.delivery:[]" bordered></a-table>
					<div class="mt20 flex justify-start align-start"  v-for="(items,indexs) in (order_data.delivery?order_data.delivery:[])" :key="indexs">
						<div class="ml20">
							<h3 >{{items.logi_name}}:{{items.logi_no}}</h3>
							<div class=" pText text-left mt20" v-for="(item,index) in (items.logi_info&&items.logi_info.data?items.logi_info.data:[])" :key="index">
								<span :class="index==0?'color3333':'colorccc'">
									{{item.context}}
								</span>
								<div class="fs12 mt10" :class="index==0?'color3333':'colorccc'">
									{{item.time}}
								</div>
							</div>
						</div>
					</div>
					
				</a-tab-pane>
				<a-tab-pane key="5" tab="订单记录">
					<a-table :columns="log_columns" :data-source="order_data.logger?order_data.logger:[]" bordered>
						<a-space slot='type' slot-scope="item,row,index">
							<span>{{loggerTypes[row.type]}}</span>
						</a-space>
					</a-table>
				</a-tab-pane>
			</a-tabs>
		</a-card>
		<a-modal :maskClosable="false" width="1000px" v-model="dialogVisible" :title="dialogType=='logi'?'发货':dialogType=='consignee'?'修改收货地址':'修改备注'" :confirm-loading="submitLoading" @ok="onSubmit"  @cancel="handleCancel" >
			<a-form-model :rules="rules" ref="formModel" size="small" :model="form">
				<div v-if="dialogType=='consignee'">
					<a-form-model-item label="收货人姓名" prop="consignee_name">
						<a-input placeholder="请输入收货人姓名" v-model="form.consignee_name" />
					</a-form-model-item>
					<a-form-model-item label="收货人手机号" prop="consignee_mobile" >
						<a-input placeholder="请输入收货人手机号" v-model="form.consignee_mobile" />
					</a-form-model-item>
					<a-form-model-item label="地区" prop="address">
						<a-cascader 
						v-model="form.address"
						:options="regionTree"
							:field-names="{ label: 'name', value: 'code', children: 'children' }"
						placeholder="请选择地区" />
					</a-form-model-item>
					<a-form-model-item label="详细地址" prop="consignee_address" >
						<a-textarea placeholder="请输入详细地址" v-model="form.consignee_address" />
					</a-form-model-item>
				</div>
				<div v-else-if="dialogType=='remark'">
					<a-form-model-item label="备注" prop="remark" >
						<a-textarea placeholder="请输入备注" v-model="form.remark" />
					</a-form-model-item>
				</div>
				<div v-else-if="dialogType=='logi'">
					<a-form-model-item label="物流/快递" prop="logi_id">
						<a-select allowClear v-model="form.logi_id" placeholder="请选择物流/快递" >
							<a-select-option v-for="(item,index) in logisticsData" :key="index" :value="item.id">{{item.name}}</a-select-option>
						</a-select>
					</a-form-model-item>
					<a-form-model-item label="物流/快递单号" prop="logi_no" >
						<a-input placeholder="请输入物流/快递单号" v-model="form.logi_no" />
					</a-form-model-item>
					<a-form-model-item label="选择发货物品" >
						<a-table rowKey="id" :row-selection="selectRow" :columns="goods_columns" :data-source="ordersItems?ordersItems:[]" bordered>
							<a-space slot='price' slot-scope="item,row,index">
								<span>¥{{$common.toDecimal2(row.price/100)}}</span>
							</a-space>
							<a-space slot='payed_amount' slot-scope="item,row,index">
								<span>¥{{$common.toDecimal2(row.payed_amount/100)}}</span>
							</a-space>
							<a-space slot='img' slot-scope="item,row,index">
								<a-avatar shape="square" :size="44" :src="IMGHOST+row.img" />
							</a-space>
							<a-space slot='is_delivery' slot-scope="item,row,index">
								<span>{{row.is_delivery==1?'已发货':'未发货'}}</span>
							</a-space>
							<a-space slot='after_sale_status' slot-scope="item,row,index">
								<span>{{row.after_sale_status==1?'售后中':'-'}}</span>
							</a-space>
						</a-table>
					</a-form-model-item>
				</div>
			</a-form-model>
		</a-modal>
	</div>
</template>
<script>
import { orders_consignee,orders_remark,orders_delivery,orders_read} from '@/api/orders'
import {brand_index} from '@/api/goods'
export default {
  
	data() {
		return {
			order_sn:'',
			IMGHOST:window.IMGHOST,
			goods_columns:[
				{title: '商品',width:80,dataIndex: 'img',scopedSlots: { customRender: 'img' }},
				{title: '订单号',dataIndex: 'order_sn'},
				{title: '商品名称',dataIndex: 'goods_name'},
				{title: '购买数量',dataIndex: 'num'},
				{title: '商品单价',dataIndex: 'price',scopedSlots: { customRender: 'price' }},
				{title: '规格描述',dataIndex: 'goods_spec_desc'},
				{title: '发货状态',dataIndex: 'is_delivery',scopedSlots: { customRender: 'is_delivery' }},
				
			],
			pay_columns:[
				{title: '支付单号',dataIndex: 'payment_id'},
				{title: '支付方式',dataIndex: 'payment',scopedSlots: { customRender: 'payment' }},
				{title: '支付金额',dataIndex: 'fee',scopedSlots: { customRender: 'fee' }},
				{title: '支付状态',dataIndex: 'trade_status',scopedSlots: { customRender: 'trade_status' }},
				{title: '支付时间',dataIndex: 'ctime'},
			],
			ship_columns:[
				{title: '发货单号',dataIndex: 'sn'},
				{title: '快递公司',dataIndex: 'logi_name'},
				{title: '快递单号',dataIndex: 'logi_no'},
				// {title: '收货人姓名',dataIndex: '4'},
				// {title: '收货电话',dataIndex: '5'},
				// {title: '收货地址',dataIndex: '6'},
			],
			return_columns:[
				{title: '退货单号',dataIndex: '1'},
				{title: '快递公司',dataIndex: '2'},
				{title: '快递单号',dataIndex: '3'},
				{title: '退货状态',dataIndex: '4'},
				{title: '退货时间',dataIndex: '5'},
			],
			log_columns:[
				{title: '订单号',dataIndex: 'order_sn'},
				{title: '操作类型',dataIndex: 'type',scopedSlots: { customRender: 'type' }},
				// {title: '描述',dataIndex: '3'},
				{title: '时间',dataIndex: 'ctime'},
			],
			dialogVisible:false,
			form:{
				order_sn:'',
				logi_id:'',
				logi_no:'',
				orders_items_id:[],
				consignee_name:'',
				consignee_mobile:'',
				consignee_address:'',
				remark:'',
				address:[],
			},
			rules:{
				consignee_name: [{ required: true, message: '请输入收货人姓名', trigger: 'blur' }],
				consignee_mobile: [{ required: true, message: '请输入收货人手机号', trigger: 'blur' }],
				consignee_address: [{ required: true, message: '请输入详细地址', trigger: 'blur' }],
				remark: [{ required: true, message: '请输入备注', trigger: 'blur' }],
				logi_no: [{ required: true, message: '请输入物流/快递单号', trigger: 'blur' }],
				address: [{ required: true, message: '请选择地区', trigger: 'change' }],
				logi_id: [{ required: true, message: '请选择物流/快递', trigger: 'change' }],
			},
			submitLoading:false,
			dialogType:'',
			order_sn:'',
			logisticsData:[],
			regionData:[],
			order_data:{},
			order_types:{'1':'普通订单', '2':'秒杀订单', '3':'预售订单', '4':'助力订单', '5':'拼团订单', '6':'送礼订单'},
			order_status_gathers:{'-1':'关闭','1':'待付款','2':'待发货','3':'部分发货','4':'已发货','5':'待评价','6':'售后中','7':'交易成功'},
			loggerTypes:{ '1':'创建订单','2':'支付订单','3':'关闭订单','4':'修改发货地址','5':'订单发货','6':'修改商家备注','7':'延长收货天数','8':'修改录单状态','9':'申请售后'},
			selectGoods:[],
			selectRow:{
				onChange: (selectedRowKeys, selectedRows) => {
					console.log(selectedRows)
					this.selectGoods = selectedRows
				},
			},
			ordersItems:[],
			brandData:[],
		}
	},
	computed: {
		regionTree(){
			if(this.regionData.length>0){
				return this.$common.treeData(this.regionData,'pcode','code',0)
			}
		}
	},
	mounted() {
		this.logisticsData = this.$db.get('logisticsList').data
		this.regionData = this.$db.get('regionList').data
		this.order_sn = this.$route.query.id
		this.get_orders_read();
		// brand_index({ page: 1, limit: 99999 }).then((res) => {
		// 	this.brandData = res.list
		// })
	},
	methods: {
		get_orders_read(){
			orders_read({order_sn:this.order_sn}).then((result)=>{
				this.order_data = result
			})
		},
		handleCancel(){
			this.submitLoading = false;
			this.dialogVisible=false
		},
		returnRegionName(code){
			let rest = this.regionData.find((item)=>{return code==item.code})
			if(rest){
				return rest.name
			}
			return ''
		},
		findBrandName(id){
			let rest = this.brandData.find((item)=>{return id==item.id})
			if(rest){
				return rest.brand_name
			}
			return ''
		},
		async onSubmit(){
			this.$refs.formModel.validate(async valid => {
				if (valid) {
					if(this.selectGoods.length==0){
						this.$message.warning('请选择发货商品')
						return
					}
					this.submitLoading = true
					if(this.dialogType=='logi'){//发货
						let params = {
							"order_sn":this.form.order_sn,
							"logi_id":this.form.logi_id,
							"logi_no":this.form.logi_no,
							"orders_items_id":[]
						}
						for (let i = 0; i < this.selectGoods.length; i++) {
							params.orders_items_id.push(this.selectGoods[i].id)
						}
						orders_delivery(params).then((result)=>{
							this.$message.success('操作成功');
							setTimeout(()=>{
								this.get_orders_read();
								this.submitLoading = false;
								this.dialogVisible=false
							},1500)
						}).catch(()=>{
							this.submitLoading = false;
						})

					}else if(this.dialogType=='consignee'){//修改收货地址
						let params = {
							"order_sn":this.form.order_sn,
							"consignee_name":this.form.consignee_name,
							"consignee_mobile":this.form.consignee_mobile,
							"consignee_province":this.returnRegionName(this.form.address[0]),
							"consignee_city":this.returnRegionName(this.form.address[1]),
							"consignee_county":this.returnRegionName(this.form.address[2]),
							"consignee_address":this.form.consignee_address	
						}
						orders_consignee(params).then((result)=>{
							this.$message.success('操作成功');
							setTimeout(()=>{
								this.get_orders_read();
								this.submitLoading = false;
								this.dialogVisible=false
							},1500)
						}).catch(()=>{
							this.submitLoading = false;
						})
						
					}else{//修改备注
						let params = {
							"order_sn":this.form.order_sn,
							"remark":this.form.remark,
						}
						orders_remark(params).then((result)=>{
							this.$message.success('操作成功');
							setTimeout(()=>{
								this.get_orders_read();
								this.submitLoading = false;
								this.dialogVisible=false
							},1500)
						}).catch(()=>{
							this.submitLoading = false;
						})
						
					}
					
				}else{
					return false
				}
			});
		},
		dialogClick(type,row)  {
			this.dialogType=type;
			if(type=='logi'){
				this.selectGoods = []
				this.ordersItems = []
				for (let i = 0; i < this.order_data.ordersItems.length; i++) {
					if(this.order_data.ordersItems[i].is_delivery==0){
						this.ordersItems.push(this.order_data.ordersItems[i])
					}
				}
			}
			this.form={
				order_sn:this.order_data.order_sn,
				logi_id:'',
				logi_no:'',
				orders_items_id:[],
				consignee_name:'',
				consignee_mobile:'',
				// consignee_province:'',
				// consignee_city:'',
				// consignee_county:'',
				consignee_address:'',
				remark:'',
				address:[],
			}
			
			this.dialogVisible=true
		},
		callback(e){
			this.tabsKey = e
		},
		_back(){
			this.$router.go(-1)
		}
	},
}
</script>
